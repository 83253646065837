
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Yacht from './component/Yacht';
import Home from './component/Home';
import YachtOne from './component/YachtOne';
import YachtTwo from './component/YachtTwo';
import YachtThree from './component/YachtThree';
import YachtFour from './component/YachtFour';
import YachtFive from './component/YachtFive';
import YachtSix from './component/YachtSix';
import Packages from './component/Packages';
import Corporate from './component/Corporate';
import Birthday from './component/Birthday';
import Propose from './component/Propose';
import Candlelit from './component/Candlelit';
import Family from './component/Family';
import Sunset from './component/sunset';
import Register from './component/Register';
import Policy from './component/Policy';

// import Footer from './component/Footer';

function App() {
  return (
    <>
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Yachts" element={<Yacht/>} />
        <Route path="/YachtOne" element={<YachtOne/>} />
        <Route path="/YachtTwo" element={<YachtTwo/>} />
        <Route path="/YachtThree" element={<YachtThree/>} />
        <Route path="/YachtFour" element={<YachtFour/>} />
        <Route path="/YachtFive" element={<YachtFive/>} />
        <Route path="/YachtSix" element={<YachtSix/>} />
        <Route path="/Packages" element={<Packages/>} />
        <Route path="/Corporate" element={<Corporate/>} />
        <Route path="/Birthday" element={<Birthday/>} />
        <Route path="/Propose" element={<Propose/>} />
        <Route path="/Candlelit" element={<Candlelit/>} />
        <Route path="/Family" element={<Family/>} />
        <Route path="/Sunset" element={<Sunset/>} />
        <Route path="/Register" element={<Register/>} />
        <Route path="/Policy" element={<Policy/>} />
        </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
