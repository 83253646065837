import React from 'react';
import '../All-css/Footer.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <>
      <footer id="footer-icon">
        <hr className="footer-line-1"/>
        <section className="footer-section">
        <div className="logo">
            <Link exact to="/">
            <img src="images/logo-1.png" alt=""/>
        </Link>
        </div>
        <div className="options">
            <h4>Options</h4><br/>
            <Link exact to="/">Home</Link><br/>
            <Link exact to="/Yachts">Our Yachts</Link><br/>
            <Link exact to="/Packages">Packages</Link><br/>
        </div>
        <div className="packages">
            <h4>Packages</h4><br/>
          <Link exact to="/Corporate">Beyond Boardrooms</Link><br/>
          <Link exact to="/Birthday">Celebrate Your Birthday</Link><br/>
          <Link exact to="/Propose">Propose Your Sweetheart</Link><br/>
          <Link exact to="/Family">Family Gathering </Link><br/>
          <Link exact to="/Candlelit">Candlelit dinner</Link><br/>
          <Link exact to="/Sunset">Sunset</Link>
        </div>
        <div className="terms">
            <h4>Terms & Conditions</h4><br/>
            <Link exact to="/Policy">Policy</Link>
        </div>
        <div className="social-links">
        <a href="https://www.instagram.com/royalvikingtourism/?fbclid=IwAR05NgqQ6gk1bUR3eqgC4qIXWjOb578Y6s54raHSC6965FEcbRUQilEyT9U"><i className="fa-brands fa-instagram"></i></a>
        <a href="https://www.facebook.com/profile.php?id=100094268243897"><i className="fa-brands fa-facebook"></i></a>   
        <a href="https://www.linkedin.com/company/royal-vikings/?viewAsMember=true "><i className="fa-brands fa-linkedin"></i></a>
        </div>
        </section>
        <hr className="footer-line-2"/>
        <p className="footer-end"><i className="fa fa-copyright" aria-hidden="true"></i>All Right Reserved</p>
    </footer>        
    </>
    )
}
