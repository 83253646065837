import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../All-css/Home.css';
import { Link } from 'react-router-dom';
export default function Home() {
  return (
    <>
      <div className="Home-Body">
      <Navbar/>
      <section className="section-1-1">
        <div className="head-left-section-1">
            <h1 className="head-heading-section-1">DUBAI</h1>
            <p className="head-para-section-1">Welcome to Dubai, the city where opulence meets the open sea! Discover a world of glamour, adventure, and relaxation as you embark on a yacht tour that promises to redefine your idea of luxury. </p>
            <Link exact to="/Register"><button className="head-btn-section-1">BOOK NOW</button></Link>
            <Link exact to="/Packages"><button className="head-btn-2" id="aboutus">MORE INFO</button></Link>
        </div>
        <div className="head-right">
            <img className="head-images-section-1" src="images/Checks.webp" alt=""/>
            <img className="head-images-section-1" src="images/second-cards.webp" alt=""/>
            <img className="head-images-section-1" src="images/third-cards.webp" alt=""/> 
        </div>
    </section>

      <section className="section-2-2" id="aboutus">
        <div className="section-2-2-left">
<h1 className="section-2-2-left-heading" >About Royal Viking</h1>
<p className="section-2-2-left-para">Escape the confines of traditional meeting spaces and embrace the extraordinary. Royal Viking Yachts presents a unique opportunity to redefine corporate gatherings on the sparkling waters of Dubai. Elevate your discussions and presentations against the backdrop of a luxury yacht, where the soothing rhythm of the sea fuels creativity and collaboration. Experience the perfect blend of business and leisure as you navigate towards success in an atmosphere designed to inspire innovation.</p>
        </div>
        <div className="section-2-right">
<img className="section-2-2-images-1" src="images/bottles.webp" alt=""/>
<img className="section-2-2-images-2" src="images/final-bottle.webp" alt=""/>
        </div>
        </section>

        <section className="section-3-3">
<div className="section-3-3-div-1">
<h1 className="section-3-3-heading">Our Services</h1>
<p className="section-3-3-para">Our professional crew members are committed to making your yacht excursion seamless and memorable. From personalized itineraries to top-notch service, we ensure that every detail is attended to, allowing you to relax and enjoy your journey to the fullest.
</p>
</div>
<div className="section-3-3-div-2">
    <img className="section-3-3-images" src="images/childrens.webp" alt=""/>
    <img className="section-3-3-images" src="images/sunsett.webp" alt=""/>
    <img className="section-3-3-images" src="images/chip.webp" alt=""/>
    
</div>
<Link exact to="/Packages"><button className="section-3-3-btn">MORE INFO</button></Link>
    </section>
    <Footer/>
      </div>
    </>
  )
}
