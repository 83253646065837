import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../All-css/Yacht.css';
import { Link } from 'react-router-dom';

export default function Yacht() {
  return (
    <>
    <div className="Yacht-Body">
      <Navbar />
      <section className="section-6">
      <div className="first-div">
        <h1 className="first-div-h1">Our <span>Yachts</span></h1>
        <p className="first-div-p">Your board meeting amidst the panoramic views of Dubai's iconic skyline, the tranquil
          waters of the
          marina serving as a backdrop for your strategic discussions. Our yachts are not just vessels; they are
          floating boardrooms equipped with state-of-the-art Audio/Video amenities. Seamlessly conduct
          presentations and foster engaging group discussions in an atmosphere that is as inspiring as it is
          conducive to productivity.</p>
        <Link exact to="/Register"><button className="section-6-BookNow-btn">BOOK NOW</button></Link>
      </div>
      <div className="second-div">
        <img src="images/s1.webp" alt="" />
        <img src="images/sh2.webp" alt="" />
        <img src="images/sh3.webp" alt="" />
        <img src="images/sh4.webp" alt="" />
        <img src="images/sh5.webp" alt="" />
        <img src="images/sh6.webp" alt="" />
        <img src="images/yy1.webp" alt="" />
        <img src="images/yy2.webp" alt="" />
        <img src="images/yy3.webp" alt="" />
      </div>
      </section>

      <section className="section-2">
    <p className="yachts-para">Escape the confines of traditional meeting spaces and embrace the extraordinary. Royal Viking Yachts presents a unique opportunity to redefine corporate gatherings on the sparkling waters of Dubai. Elevate your discussions and presentations against the backdrop of a luxury yacht, where the soothing rhythm of the sea fuels creativity and collaboration. Experience the perfect blend of business and leisure as you navigate towards success in an atmosphere designed to inspire innovation.</p>
    <div className="sec2-main-div">
        <div className="card card-1">
            <img src="images/ship1/oceann4.webp" className="card-img-top card-1-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-1-heaing" >Call For Price - 60 Guests</h5>
              <p className="card-text card-1-para" >Royal Viking 143 ft-Triple Deck Yacht</p>
              <Link exact to="/YachtOne" className="btn btn-info card-1-btn">View More</Link>
            </div>
          </div>
          
        <div className="card card-2" >
            <img src="images/ship2/carmenn3.webp" className="card-img-top card-2-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-2-heaing">Call For Price - 40 Guests</h5>
              <p className="card-text card-2-para">Royal Viking 90 ft. Yacht</p>
              <Link exact to="/YachtTwo" className="btn btn-primary card-2-btn">View More</Link>
            </div>
          </div>
          <div className="card card-3">
            <img src="images/ship3/yy3.webp" className="card-img-top card-3-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-3-heaing">Call For Price - 50 Guests</h5>
              <p className="card-text  card-3-para">Royal Viking  101 ft. Yacht</p>
              <Link exact to="/YachtThree" className="btn btn-primary card-3-btn">View More</Link>
            </div>
          </div>
          <div className="card card-3">
            <img src="images/ship4/dreamm1.webp" className="card-img-top card-3-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-3-heaing">Call For Price - 50 Guests</h5>
              <p className="card-text  card-3-para">Royal Viking  100 ft. Yacht</p>
              <Link exact to="/YachtFour" className="btn btn-primary card-3-btn">View More</Link>
            </div>
          </div>
          <div className="card card-3">
            <img src="images/ship5/nightstarr1.webp" className="card-img-top card-3-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-3-heaing">Call For Price - 10 Guests</h5>
              <p className="card-text  card-3-para">Royal Viking  42 ft. Yacht</p>
              <Link exact to="/YachtFive" className="btn btn-primary card-3-btn">View More</Link>
            </div>
          </div>
          <div className="card card-3">
            <img src="images/ship6/skyraa1.webp" className="card-img-top card-3-img" alt="..."/>
            <div className="card-body">
              <h5 className="card-title card-3-heaing">Call For Price - 33 Guests</h5>
              <p className="card-text  card-3-para">Royal Viking  80 ft. Yacht</p>
              <Link exact to="/YachtSix" className="btn btn-primary card-3-btn">View More</Link>
            </div>
          </div>
    </div>
</section>
<Footer/>
</div>
    </>

  )
}
