import React from 'react'
import Navbar from './Navbar'
import '../All-css/YachtOne.css'
import Footer from './Footer'
import { Link } from 'react-router-dom';
export default function YachtTwo() {
  return (
    <>
    <div className="Yacht-Body">
   <Navbar/>
   <section className="section-1-yachtOne">
        
     <div className="left-div-YachtsOne">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/ship2/carmenn3.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/ship2/carmenn2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn4.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn5.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn6.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn7.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn8.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship2/carmenn9.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
     </div>
     <div className="right-div-YachtsOne">
    
        <h1 className="section-2-h1-right">CARMEN</h1>
        <p className="section-2-para-right">BUILD : SUN SEEKER <br/><br/>
            LENGTH : 90 FT <br/><br/>
            CABIN 1 MASTER. 1 VIP, 1 DOUBLE <br/><br/>
            SLEEP : 15 <br/><br/>
            CREW : PHILIPPINES,INDIA,SOUTH AFRICA <br/><br/>
            CAPACITY : UP TO 40 PAX
        </p>

        <Link exact to="/Register"><button className="BookNow-btn-YachtsOne">BOOK NOW</button></Link>
 </div>
   
    </section>
    <Footer/>
    </div>
    </>

    )
}
