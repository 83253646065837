import React from 'react'
import '../All-css/sunset.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Sunset() {
  return (
    <>
    <div className="Sunset-Body">
    <Navbar/>

   
    <section className="section-1-SS">
        <div className="right-div-SS">
    
            <h1 className="section-2-h1-right-SS">The <span>Sunset</span> in  Dubai </h1>
            <p className="section-2-para-right-SS">The sunset in Dubai is truly dazzling, with the sounds of the Arabian waves which turn exceedingly soothing. Experiencing it once, you would be convinced and endorse the comments of those who dub the sunset cruise as an amazing experience.

                <br/><br/>
                Going for a sunset cruise with your partner or friends is the awesome blend outing and relaxing at the same time. As your yacht starts to sail from Marina, you start witnessing Dubai from an entirely different perspective.<br/><br/>
                As your yacht glides down Dubai’s spectacular coastline, you watch the sunset sparkle in the Arabian Gulf waters and catch sight of the Burj al-Arab towering on the horizon amidst the dramatic oranges and purples of the evening sky. As you approach Palm Jumeirah, you encounter luxury houses lining the white sandy beaches. The Atlantis Palm is even more enchanting. <br/><br/>
                Hold on as it’s time for the sun to set in the Arabian Sea. You will forget to take the sip of your drink or even may forget to respond to the comments of your mate since the scene of sunset is so stunning. Now, the day is over but not the party, the DJ is going to rock you with melodies. You will also enjoy the onboard gourmet dinner with brimming drinks.
            </p>

            <Link exact to="/Register"><button className="BookNow-btn-SS">BOOK NOW</button></Link>
     </div>
     <div className="left-div-SS">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/package-6/sunset1.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/package-6/sunset2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-6/sunset3.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-6/sunset4.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
     </div>
   
    </section>




    <Footer/>
    </div>
    </>
  )
}
