import React from 'react'
import '../All-css/candlelit.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Candlelit() {
  return (
    <>
    <div className="Candlelit-Body">
    <Navbar/>

    <section className="section-1-CD">
        <div className="right-div-CD">
    
            <h1 className="section-2-h1-right-CD"><span>Candle lit</span>  dinner on Yacht</h1>
            <p className="section-2-para-right-CD">At Royal Viking Yachts, we invite you to embark on an enchanting journey through the heart of Dubai with our exclusive Candlelit Dinner on Yacht & Cruise package. Imagine the city lights glistening on the water as you and your loved one share an intimate evening on board one of our luxurious yachts. This experience is designed to create memories that will last a lifetime.<br/><br/>
                Indulge in Luxury and Opulence:<br/><br/>
                Our private dinner cruise takes place in the midst of Dubai's dazzling cityscape, providing you with unparalleled views of the Arabian Gulf. The opulence of our yachts sets the stage for a truly memorable evening. You'll be pampered with impeccable service, luxurious rooms, spacious dining areas, and expansive decks where you can soak in the romantic ambiance.<br/><br/>
                A Night of Enchantment:
                <br/><br/>
                Whether you're celebrating a special occasion or simply want to rekindle your romance, our Candlelit Dinner on Yacht & Cruise is the perfect choice. Here's why:<br/><br/>
                Celebrate Love: Make birthdays, anniversaries, or Valentine's Day extraordinary by choosing a yacht candlelit dinner. It's the perfect setting to express your love and create cherished memories. <br/><br/>
                
Escape the City Hustle: Leave the city's hustle and bustle behind and embrace the tranquility of the sea. Enjoy the soothing sounds of the waves as you savor every moment.

           

            </p>

            <Link exact to="/Register"><button className="BookNow-btn-CD">BOOK NOW</button></Link>
     </div>
     <div className="left-div-CD">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/package-4/candel4.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/package-4/candel2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-4/candel3.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-4/candel1.webp" className="d-block w-100 mt-4" alt="..."/>
              </div> 
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <p className="section-2-para-2-right-CD"> 
            Romantic Ambiance: Our yachts are beautifully decorated with flowers, candles, and elegant furnishings, creating an atmosphere of pure romance. Soft music and thoughtful food presentation complete the experience.
<br/><br/>
Privacy: Experience unparalleled privacy as you sail away from the city. It's just you, your loved one, and the vast expanse of the ocean. <br/><br/>
Gourmet Delights: Indulge in a culinary journey featuring a bespoke menu of gourmet cuisines from around the world. Choose from Asian, Chinese, Mexican, Thai, and more to suit your palate.
<br/><br/>Scenic Views: Our yachts can sail to various picturesque locations, providing the perfect backdrop for your special evening. From iconic landmarks to breathtaking seascapes, you'll have plenty to admire.
<br/><br/>
Book Your Romantic Getaway:
<br/><br/>
A Candlelit Dinner on Yacht & Cruise with Royal Viking Yachts is more than a meal; it's an experience that captures the essence of Dubai's elegance and grandeur. Whether you're celebrating love, marking a milestone, or simply seeking a romantic escape, our yachts offer the perfect setting for your special moments. Make your reservation today and set sail on an unforgettable journey of love and luxury.
<br/><br/></p>
     </div>
   
    </section>
    





    <Footer/>
    </div>
    </>
  )
}
