import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../All-css/YachtOne.css';
import { Link } from 'react-router-dom';
export default function YachtOne() {
    return (
        <>
        <div className="Yacht-Body">
            <Navbar/>
            <section className="section-1-yachtOne">
                <div className="left-div-YachtsOne">
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="images/ship1/oceann4.webp" className="d-block  w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann2.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann3.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann5.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann6.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann7.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann8.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="images/ship1/oceann9.webp" className="d-block w-100 mt-4" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className="right-div-YachtsOne">
                    <h1 className="section-2-h1-right-div-YachtsOne">OCEAN DREAM</h1>
                    <p className="section-2-para-right">BUILD : OCEA <br /><br />
                        LENGTH : 143 FT-TRIPLE DECK <br /><br />
                        CABIN 4, VIP 1-JACUZZI <br /><br />
                        SLEEP : 20 <br /><br />
                        CREW : PHILIPPINES,INDIA,SOUTH AFRICA <br /><br />
                        CAPACITY : UP TO 120 PAX
                    </p>

                    <Link exact to="/Register"><button className="BookNow-btn-YachtsOne">BOOK NOW</button></Link>
                </div>
            </section>
         <Footer/>
         </div>
        </>
    )
}
