import React from 'react'
import Navbar from './Navbar'
import '../All-css/YachtThree.css'
import Footer from './Footer'
import { Link } from 'react-router-dom';
export default function YachtThree() {
  return (   
    <>
    <div className="Yacht-Body">
    <Navbar/>
    <section className="section-1-yachtThree">
        
     <div className="left-div-yachtThree">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/ship3/yy3.webp" className="d-block w-100 mt-4 pic-1" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/ship3/yachtt1.webp" className="d-block w-100 mt-4 " alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt3.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt4.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt5.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt6.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt7.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt8.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt9.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt10.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/ship3/yachtt11.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
     </div>
     <div className="right-div">
    
        <h1 className="section-2-h1-right">INFINITY 7</h1>
        <p className="section-2-para-right">BUILD : MAJESTY <br/><br/>
            LENGTH : 101 FT <br/><br/>
            CABIN 1 MASTER. 2 VIP, 2 DOUBLE <br/><br/>
            SLEEP : 12 <br/><br/>
            CREW : PHILIPPINES,INDIA,SOUTH AFRICA <br/><br/>
            CAPACITY : UP TO 50 PAX
        </p>

        <Link exact to="/Register"><button className="BookNow-btn-YachtsThree">BOOK NOW</button></Link>
 </div>
   
    </section>
    <Footer/>
    </div>
    </>
  )
}
