import React, { useState } from 'react';
import '../All-css/Nav.css';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isNavActive, setNavActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!isNavActive);
  };

  return (
    <div>
      <div className={`nalog ${isNavActive ? 'active' : ''}`}>
        <nav>
          <div className="nav-options">
            <a href="/">
              <img src="images/logo-1.png" alt="" className="logo" />
            </a>
          </div>
          <div className="toggle_btn" onClick={toggleNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
          <ul className={`link ${isNavActive ? 'active' : ''}`}>
            <li>
              <Link exact to="/">
                Home
              </Link>
            </li>
            <li>
              <Link exact to="/Yachts">
                Our Yachts
              </Link>
            </li>
            <li>
              <Link exact to="/Packages">
                Packages
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
