import React from 'react'
import '../All-css/birthday.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Birthday() {
  return (
    <>
    <div className="Birthday-Body">
    <Navbar/>

    <section className="section-1-BD">
        <div className="right-div-BD">
    
            <h1 className="section-2-h1-right-BD">Celebrate Your<span> Birthday in Ultimate Luxury </span> aboard a  Royal Viking Yacht!</h1>
            <p className="section-2-para-right-BD">Open with scenic shots of luxury yachts sailing against the backdrop of Dubai's stunning skyline. Lively, upbeat music plays in the background.<br/><br/>
                Welcome to a celebration like no other, where luxury meets the high seas. Introducing Royal Viking Yachts – your gateway to an unforgettable birthday experience in the heart of Dubai.<br/><br/>
                Imagine celebrating your special day surrounded by the breathtaking Dubai skyline, with the gentle waves beneath you and your loved ones by your side. Royal Viking Yachts specializes in creating exquisite birthday memories that will last a lifetime.<br/><br/>
                <b>INCLUDES </b>
                *Luxury Yacht
                Birthday Cake
                *Multiple Food Menus
                Birthday Decoration
                *Flower Bouquet
                Water, Ice & Soft Drinks
                Inbuilt music system
                <br/><br/>
                Show the Royal Viking Yachts logo and contact information, along with clips of the yacht sailing gracefully. <br/><br/>
                Show the yacht in full party mode – people celebrating, dancing, and enjoying the luxurious ambiance. <br/><br/>
                And the best part? Our charter specialists are here to make your dreams come true. From selecting the perfect gourmet menu to curating thematic experiences, from setting the mood with the right music to planning engaging games, we've got every detail covered to ensure this birthday becomes an unforgettable masterpiece.
           

            </p>

            <Link exact to="/Register"><button className="BookNow-btn-BD">BOOK NOW</button></Link>
     </div>
     <div className="left-div-BD">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/package-2/hbdd1.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/package-2/hbdd5.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-2/hbdd3.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-2/hbdd2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-2/hbdd6.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-2/hbdd7.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <p className="section-2-para-2-right-BD"> 
            [Cut to scenes of various yacht sizes and activities – large groups enjoying a barbecue, guests participating in water sports, people trying their hand at fishing, and everyone having a blast dancing.]
<br/><br/>
Whether you're hosting a cozy gathering or inviting a large group, our fleet of yachts can accommodate anywhere from 50 to 100 guests. Throw a barbecue party, challenge your friends to water sports, showcase your fishing prowess, or simply dance the night away – the possibilities are endless! <br/><br/>
[Show the yacht sailing gracefully against the backdrop of Dubai's iconic skyline.]
<br/><br/>Your dream birthday celebration awaits on the shimmering waters of Dubai Marina. So, why wait? Make this year's birthday a tale to be told for years to come
<br/><br/>
[Cut to a stunning shot of the yacht cruising into the horizon as the sun sets.]
<br/><br/>
Your best birthday experience is just a click away. Contact us now to turn your birthday celebration into an extraordinary memory you'll cherish forever. Royal Viking Yachts – where luxury meets celebration.
<br/><br/></p>
     </div>
   
    </section>
        





    <Footer/>
    </div>
    </>
  )
}
