import React from 'react'
import '../All-css/family.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Family() {
  return (
    <>
    <div className="Family-Body">
    <Navbar/>

    <section className="section-1-FG">
        <div className="right-div-FG">
    
            <h1 className="section-2-h1-right-FG"><span>Family Gathering</span> on Luxury Yacht </h1>
            <p className="section-2-para-right-FG">Get together with your family for an exclusive event or adventure and spend some unforgettable moments on the lap of a chartered yacht in the middle of the serene sea.Royal Viking Yachts create a memorable and personal ambiance to cater all your needs onboard. From music, drinks, foods and water sports activities, we make you feel exceptionally fantastic while keeping up with your privacy. Enjoy the picturesque view of the sunset in cool sea breeze with your family and collect tomorrow’s cherished memories.

                <br/><br/>
                Yachting at Dubai oceans remains one of the first and foremost options among family folks. Sailing on a luxury cruise provides the entertainment options for the complete family. In addition to the connection to nature, the elders could indulge in recreational activities with their children, sharing laughter, exchanging creative ideas with a partner. Having tired of all those joyful activities, sailing back at slow & relaxing pace would vanish all your tiredness. <br/><br/>
                Nothing matches the charm of spending active vacations with family and it gets ideal when some event like celebrating wedding anniversary and birthday parties. It’s the best place for the couple to have dinner as the privacy & serenity could not be found at any other place. You may need not to turn on the music during those moments as the environment is far melodious. Spending such an evening together, even if once a year, would add to the wonderful memories of your life and you would definitely like to grab some selfies too.
            </p>

            <Link exact to="/Register"><button className="BookNow-btn-FG">BOOK NOW</button></Link>
     </div>
     <div className="left-div-FG">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/package-5/family1.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/package-5/family6.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-5/family2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
     </div>
   
    </section>
   




    <Footer/>
    </div>
    </>
  )
}
