import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import '../All-css/Yacht.css';
import { Link } from 'react-router-dom';
export default function YachtFour() {
  return (
    <>
    <div className="Yacht-Body">
    <Navbar/>
     <section className="section-1-yachtOne">

<div className="left-div-YachtsOne">
    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img src="images/ship4/dreamm1.webp" className="d-block  w-100 mt-4" alt="..."/>
            </div>
            <div className="carousel-item">
                <img src="images/ship4/dreamm2.webp" className="d-block w-100 mt-4" alt="..."/>
            </div>
            <div className="carousel-item">
                <img src="images/ship4/dreamm3.webp" className="d-block w-100 mt-4" alt="..."/>
            </div>
            <div className="carousel-item">
                <img src="images/ship4/dreamm4.webp" className="d-block w-100 mt-4" alt="..."/>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
</div>
<div className="right-div-YachtsOne">

    <h1 className="section-2-h1-right">DREAM</h1>
    <p className="section-2-para-right">BUILD : MAJESTY<br/><br/>
        LENGTH : 100 FT <br/><br/>
        CABIN 2, VIP 1 <br/><br/>
        SLEEP : 25 <br/><br/>
        CREW : PHILIPPINES,INDIA,SOUTH AFRICA <br/><br/>
        CAPACITY : UP TO 100 PAX
    </p>

    <Link exact to="/Register"><button className="BookNow-btn-YachtsOne">BOOK NOW</button></Link>
</div>

</section>
<Footer/>
</div>
    </>
  )
}
