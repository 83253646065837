import React, { useState } from 'react';
import '../All-css/Check.css';
import Navbar from './Navbar';
import 'react-phone-input-2/lib/style.css'; // Import the library's CSS
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert
import Footer from './Footer';

export default function Register() {
  let history = useNavigate();
  const [data, setData] = useState({
    cname: "",
    cemail: "",
    code: "",
    cnumber: "",
    cdate: "",
    ctime: "",
    chours: "",
    cguest: "",
    cmessage: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  // const handlePhoneChange = (selectedOption) => {
  //   setData({ ...data, code: selectedOption.value });
  // };


  const submitForm = (e) => {
    e.preventDefault();
    const sendData = {
      cname: data.cname,
      cemail: data.cemail,
      cphone: data.cphone,
      cdate: data.cdate,
      ctime: data.ctime,
      chours: data.chours,
      cguest: data.cguest,
      cmessage: data.cmessage,
    }

    // Show "Please wait" alert while the request is in progress
    Swal.fire({
      title: 'Please Wait',
      text: 'Your data is being processed',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      allowOutsideClick: false, // Prevent user from closing the alert
    });

    axios.post('/insert.php', sendData)
      .then((result) => {
        console.log(result.data); // Log the response data
        if (result.data.status === 'valid') {
          Swal.fire({
            title: 'Success',
            text: 'Data and email have been sent successfully',
            icon: 'success',
          });
          history('/');
        }
        else {
          Swal.fire({
            title: 'Error',
            text: 'Invalid User',
            icon: 'error',
          });
        }
      })
      .catch((error) => {
        console.error(error); // Log any errors
        // Handle errors as needed
      });
  }

  return (
    <>
      <Navbar />
      <section className='Form-Body'>
        <div className="form">
          <form method="post" onSubmit={submitForm}>
            <h1 className="form-heading">BOOKING</h1>
            <input
              type="text"
              placeholder="Enter Your Name"
              className="form-name"
              name="cname"
              pattern="^[A-Za-z ]{3,20}$"
              required
              onChange={handleChange}
              value={data.cname}
            />
            <input
              type="email"
              placeholder="Enter Your Email"
              className="form-email"
              name="cemail"
              required
              onChange={handleChange}
              value={data.cemail}
            />
            <div className="phone-country">
              <PhoneInput
                country={'us'} // Set the default country (e.g., United States)
                value={data.cphone}
                className="Countrycode-input"
                onChange={(value, country, e, formattedValue) => {
                  // Handle the change for both country code and phone number here
                  // Extract the country code and phone number from the `value` object
                  const countryCode = country.dialCode;
                  const phoneNumber = formattedValue;

                  // Update the state for both country code and phone number
                  setData({ ...data, code: countryCode, cphone: phoneNumber });
                }}
                inputProps={{
                  name: 'cphone',
                  required: true,
                }}
              />
            </div>

            <div className="form-div">
              <input
                type="date"
                className="form-date"
                name="cdate"
                required
                onChange={handleChange}
                value={data.cdate}
              />
              <input
                type="time"
                className="form-time"
                name="ctime"
                required
                onChange={handleChange}
                value={data.ctime}
              />
              <input
                type="number"
                placeholder="No of hours"
                name="chours"
                className="form-hours"
                required
                onChange={handleChange}
                value={data.chours}
              />
            </div>
            <input
              type="number"
              placeholder="No of Guest"
              name="cguest"
              className="form-guest"
              required
              onChange={handleChange}
              value={data.cguest}
            />
            <textarea
              placeholder="Message"
              name="cmessage"
              className="form-message"
              required
              onChange={handleChange}
              value={data.cmessage}
            ></textarea>
            {/* Other input fields go here */}
            <button type="submit" className="form-btn" name="savebtn">Register</button>
          </form>
        </div>
        <Footer/>
      </section>
    </>
  );
}




