import React from 'react'
import Navbar from './Navbar'
import '../All-css/YachtOne.css'
import Footer from './Footer'
import { Link } from 'react-router-dom';
export default function YachtFive() {
  return (
   <>
   <div className="Yacht-Body">
   <Navbar/>
    <section class="section-1-yachtOne">
     <div class="left-div-YachtsOne">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="images/ship5/nightstarr1.webp" class="d-block  w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr2.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr3.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr4.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr5.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr6.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr7.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship5/nightstarr8.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
     </div>
     <div class="right-div-YachtsOne">
    
        <h1 class="section-2-h1-right">NIGHT STAR</h1>
        <p class="section-2-para-right">BUILD : FAIRLINE<br/><br/>
            LENGTH : 42 FT <br/><br/>
            CABIN : 1 MASTER ,1 DOUBLE <br/><br/>
            OTHERS : 1 KITCHEN, 1 WASHROOM  <br/><br/>
            CAPACITY : UP TO 12 PAX
        </p>

        <Link exact to="/Register"><button class="BookNow-btn-YachtsOne">BOOK NOW</button></Link>
 </div>
   
    </section>
    <Footer/>
    </div>
   </>
  )
}
