import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import '../All-css/Policy.css';

export default function Policy() {
  return (
    <>
    <Navbar/>
        <div className="Body-Policy">
      <section>
        <div className="content">
            <h1>Online Payments</h1>
            <p>We accept payments online using Visa and MasterCard credit/debit card in AED (or any other agreed
                currency).</p>
            <h1>UAE Law abidance</h1>
            <p>Our clients are advised to ensure their strict abidance to the following laws and practices of UAE, while
                on board:</p>
            <p className="third-para">UAE law makes Captain and crew member contingent mandatory for charters.
                Individuals, on board, shall not exceed the quantity mentioned in the charter of registration.
                Individuals, below the 21 years of age, shall remain under the supervision of a devoted adult.
                Refrain from violating UAE regulations regarding consumption of alcohol and fishing.
                All types of pets and animals are strictly prohibited from taking on board.
            </p>
        </div>
        <div className="heading">
            <h1>Terms & Conditions</h1>
            <h3>CHARTER POLICY:</h3>
            <p>Trips will only be booked when confirmation and payment is received from the Client along with this form duly signed. Payment Schedule: Payment Terms: 50% Down Payment at the time of booking and the balance before the charter Catering Payment: Terms: 100% of the total cost 7 working days prior to the charter (non-refundable) Mode of Payment: 1. By Cash 2. By Cheque (If paying by Cheque, cheque must be deliver to us 4 days before the charter) 3. By Bank Transfer (If paying by Bank Transfer, we would require 100% payment based on total charter value to be in our account 7 working days prior to Trip, transfer receipt must be sent either by fax or email)</p>
            <h3>CANCELLATION BY CLIENT</h3>
            <p>Cancellation of this Charter by Client shall result in forfeiture of deposit</p>
            <h3>CANCELLATION BY ROYAL VIKING TOURISM L.L.C </h3>
            <p>
                1. Where weather does not allow travel upon the waters, the cruise may be held at dockside or at the lagoon. 
                <br/> <br/>
                2. Where mechanical issues do not allow travel upon the waters, the cruise maybe held at the dockside or at the lagoon. Should mechanical issues force cancellation of the cruise, refunds or rescheduling the Charter shall be sole remedy for Client. In the case of a reschedule, deposit refund remains subject to this agreement and will be subject to availability.
                </p>
                <h3>Prohibited Drugs</h3>
                <p>Client is responsible and must ensure that no prohibited drugs are served, consumed and possessed by him, any of his guests and contracted service providers while on board.</p>
        </div>
    </section> 
    <Footer/>
        </div>
    </>
  )
}
