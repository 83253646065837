import React from 'react'
import Navbar from './Navbar'
import '../All-css/YachtOne.css'
import Footer from './Footer'
import { Link } from 'react-router-dom';
export default function YachtSix() {
  return (
    <>
    <div className="Yacht-Body">
    <Navbar/>
    <section class="section-1-yachtOne">
     <div class="left-div-YachtsOne">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="images/ship6/skyraa1.webp" class="d-block  w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa2.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa3.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa4.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa5.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa6.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa7.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/ship6/skyraa8.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
     </div>
     <div class="right-div-YachtsOne">
    
        <h1 class="section-2-h1-right">SKYRA</h1>
        <p class="section-2-para-right">
            LENGTH : 80 FT <br/><br/>
            CABIN : 1 MASTER , 1 SINGLE <br/><br/>
            CAPACITY : UP TO 27 PAX
            OTHERS : FLYBRIDGE, 1 KITCHEN, 2 WASHROOM , 1 BARCOUNTER <br/><br/>
        </p>

        <Link exact to="/Register"><button class="BookNow-btn-YachtsOne">BOOK NOW</button></Link>
 </div>
   
    </section>
    <Footer/>
    </div>
    </>
  )
}
