import React from 'react'
import Navbar from './Navbar'
import '../All-css/Packages.css'
import Footer from './Footer'
import { Link } from 'react-router-dom'

export default function Packages() {
    return (
        <>
            <div className="Packages-Body">
                <Navbar />
                <section className="section-1-packages">
                    <div className="head-left">
                        <h1 className="head-heading">DUBAI</h1>
                        <p className="head-para">Dubai, the glittering gem of the United Arab Emirates</p>
                        <Link exact to="/Register"><button className="head-btn">BOOK NOW</button></Link>
                        <a href="#pack"><button className="head-btn-2">MORE INFO</button></a>
                    </div>
                    <div className="head-right">
                        <img className="head-images" src="images/Checks.webp" alt="" />
                        <img className="head-images" src="images/second-cards.webp" alt="" />
                        <img className="head-images" src="images/third-cards.webp" alt="" />
                    </div>
                </section>

                <section className="section-2_2">
                    <h1 className="section-2_2-h1" id="pack">What We <span>Provide</span></h1>
                    <div className="main-div">
                        <div className="left-div-packages-1">
                            <img className="left-image" src="images/meeting.webp" alt="" />
                        </div>
                        <div className="right-div-Packages">
                            <h1 className="section-2_2-h1-right">Beyond Boardrooms: Redefine<span> Corporate Meetings</span> on a  Lavish Dubai Yacht"</h1>
                            <p className="section-2_2-para-right">"Royal Viking Yachts transforms corporate meetings in Dubai with luxurious yacht venues, offering stunning cityscape backdrops and cutting-edge technology. Their skilled hospitality team ensures seamless experiences, making business gatherings memorable and productive on the waters of Dubai."</p>
                            <Link exact to="/Corporate"><button className="BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                    </div>
                </section>

                <div className="line-1"></div>
                <div className="line-2"></div>

                <section className="section-3_3">
                    <div className="section-3_3-main-div">
                        <div className="section-3_3-right-div-Packages">
                            <h1 className="section-3_3-h1-right">Celebrate Your<span> Birthday in Ultimate Luxury </span> aboard a  Royal Viking Yacht!</h1>
                            <p className="section-3_3-para-right">Royal Viking Yachts offers an unparalleled birthday celebration experience in Dubai, set against the stunning skyline. Their package includes a luxury yacht, birthday cake, multiple food options, decorations, and more. Charter specialists ensure every detail is perfect. With a fleet accommodating 50 to 100 guests, guests can enjoy barbecues, water sports, and dancing. Contact Royal Viking Yachts for a memorable, luxury-filled birthday celebration on Dubai's sparkling waters.</p>
                            <Link exact to="/Birthday"><button className="section-3_3-BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                        <div className="section-3_3-left-div-packages-1">
                            <img className="section-3_3-left-image" src="images/hbd.webp" alt="" />
                        </div>
                    </div>
                </section>

                <div className="line-3"></div>
                <div className="line-4"></div>

                <section className="section-4_4">
                    <div className="section-4_4-main-div">
                        <div className="section-4_4-left-div-packages-1">
                            <img className="section-4_4-left-image" src="images/romantic.webp" alt="" />
                        </div>
                        <div className="section-4_4-right-div-Packages">
                            <h1 className="section-4_4-h1-right"><span>Propose Your Sweeteart</span> In  Unforgettable Style </h1>
                            <p className="section-4_4-para-right">Are you ready to take your love story to new heights? Imagine this,A breathtaking sunset, the gentle sway of the sea, and the love of your life by your side. At Royal Viking Yachts, we invite you to make your proposal an extraordinary and unforgettable moment.</p>
                            <Link exact to="/Propose"><button className="section-4_4-BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                    </div>
                </section>

                <div className="line-5"></div>
                <div className="line-6"></div>

                <section className="section-5_5">
                    <div className="section-5_5-main-div">
                        <div className="section-5_5-right-div-Packages">
                            <h1 className="section-5_5-h1-right"><span>Candle lit</span>  dinner on Yacht</h1>
                            <p className="section-5_5-para-right">Experience the enchantment of Dubai with Royal Viking Yachts' exclusive Candlelit Dinner on Yacht & Cruise package. Enjoy opulent luxury, cityscape views, and an intimate atmosphere on their luxurious yachts. This offering is perfect for special occasions or a romantic escape, featuring privacy, gourmet dining, and scenic backdrops. Create unforgettable moments of love and luxury by booking your romantic getaway today.</p>
                            <Link exact to="/Candlelit"><button className="section-5_5-BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                        <div className="section-5_5-left-div-packages-1">
                            <img className="section-5_5-left-image" src="images/candel.webp" alt="" />
                        </div>
                    </div>
                </section>

                <div className="line-3"></div>
                <div className="line-4"></div>

                <section className="section-4_4">
                    <div className="section-4_4-main-div">
                        <div className="section-4_4-left-div-packages-1">
                            <img className="section-4_4-left-image" src="images/familyy.webp" alt="" />
                        </div>
                        <div className="section-4_4-right-div-Packages">
                            <h1 className="section-4_4-h1-right"><span>Family Gathering</span> on Luxury Yacht </h1>
                            <p className="section-4_4-para-right">Get together with your family for an exclusive event or adventure and spend some unforgettable moments on the lap of a chartered yacht in the middle of the serene sea.Royal Viking Yachts create a memorable and personal ambiance to cater all your needs onboard. From music, drinks, foods and water sports activities, we make you feel exceptionally fantastic while keeping up with your privacy. </p>
                            <Link exact to="/Family"><button className="section-4_4-BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                    </div>
                </section>

                <div className="line-5"></div>
                <div className="line-6"></div>

                <section className="section-5_5">
                    <div className="section-5_5-main-div">
                        <div className="section-5_5-right-div-Packages">
                            <h1 className="section-5_5-h1-right">The <span>Sunset</span> in  Dubai</h1>
                            <p className="section-5_5-para-right">The sunset in Dubai is truly dazzling, with the sounds of the Arabian waves which turn exceedingly soothing. Experiencing it once, you would be convinced and endorse the comments of those who dub the sunset cruise as an amazing experience.Going for a sunset cruise with your partner or friends is the awesome blend outing and relaxing at the same time. As your yacht starts to sail from Marina, you start witnessing Dubai from an entirely different perspective.</p>
                            <Link exact to="/Sunset"><button className="section-5_5-BookNow-btn">MORE DETAILS</button></Link>
                        </div>
                        <div className="section-5_5-left-div-packages-1">
                            <img className="section-5_5-left-image" src="images/sunsett.webp" alt="" />
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    )
}
