import React from 'react'
import '../All-css/corporate.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Corporate() {
  return (
    <>
    <div className="Corporate-Body">
    <Navbar/>

    <section className="section-1-BBR">
        <div className="right-div-BBR">
    
            <h1 className="section-2-h1-right-BBR">Beyond Boardrooms: Redefine<span> Corporate Meetings</span> on a  Lavish Dubai Yacht"</h1>
            <p className="section-2-para-right-BBR">Escape the confines of traditional meeting spaces and embrace the extraordinary. Royal Viking Yachts presents a unique opportunity to redefine corporate gatherings on the sparkling waters of Dubai. Elevate your discussions and presentations against the backdrop of a luxury yacht, where the soothing rhythm of the sea fuels creativity and collaboration. Experience the perfect blend of business and leisure as you navigate towards success in an atmosphere designed to inspire innovation.<br/><br/>
                Your board meeting amidst the panoramic views of Dubai's iconic skyline, the tranquil waters of the marina serving as a backdrop for your strategic discussions. Our yachts are not just vessels; they are floating boardrooms equipped with state-of-the-art Audio/Video amenities. Seamlessly conduct presentations and foster engaging group discussions in an atmosphere that is as inspiring as it is conducive to productivity. <br/><br/>
                However, our commitment to excellence extends beyond technology. Our dedicated and professionally trained staff are experts in hospitality, ensuring your guests are well taken care of throughout the journey. Indulge in our curated selection of fresh and delectable cuisine, complemented by an array of beverages that cater to every palate. <br/><br/>
                Whether you're aiming to forge new partnerships, celebrate milestones, or strategize for the future, the setting you choose matters. Let Royal Viking Yachts be your partner in creating an unforgettable corporate experience that combines luxury, innovation, and the unparalleled charm of the open waters. <br/><br/>
                Contact us today to embark on a new era of corporate gatherings, one that redefines success amidst the waves of luxury and refinement.
            </p>

            <Link exact to="/Register"><button className="BookNow-btn-BBR">BOOK NOW</button></Link>
     </div>
     <div className="left-div-BBR">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="images/package-1/bbb4.webp" className="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div className="carousel-item">
                <img src="images/package-1/bbb2.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
              <div className="carousel-item">
                <img src="images/package-1/bbb3.webp" className="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
     </div>
   
    </section>
    





    <Footer/>
    </div>
    </>
  )
}
