import React from 'react'
import '../All-css/propose.css'
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function Propose() {
  return (
    <>
    <div className="Propose-Body">
    <Navbar/>

    <section class="section-1-PYSH">
        <div class="right-div-PYSH">
    
            <h1 class="section-2-h1-right-PYSH"><span>Propose Your Sweeteart</span> In  Unforgettable Style </h1>
            <p class="section-2-para-right-PYSH">Are you ready to take your love story to new heights? Imagine this: A breathtaking sunset, the gentle sway of the sea, and the love of your life by your side. At Royal Viking Yachts, we invite you to make your proposal an extraordinary and unforgettable moment..<br/><br/>
                Why Choose a Yacht Proposal?<br/><br/>
                Incredible Ideas: Our team specializes in creating the perfect atmosphere for your proposal. From romantic decor to customized experiences, we've got you covered.<br/><br/>
                No Need for Event Planners: You don't have to stress about hiring event planners. We provide everything you need to make your proposal magical.
                <br/><br/>
                Absolute Confidence: With the stunning backdrop of Dubai's coastline, you'll feel confident and ready to profess your love. <br/><br/>
                The Royal Viking Yachts Experience <br/><br/>
                Luxury Cruise: Step aboard our exquisite yacht and let the waves of luxury carry you away. Our experienced crew will ensure your every need is met, leaving you free to focus on your special moment. <br/><br/>
                Picture-Perfect Setting: Picture yourself surrounded by the endless expanse of the sea, the clear blue sky, and complete privacy. It's the perfect stage for your love story. <br/><br/>
                A 'Yes' in Your Favor: With the enchanting ambiance we provide, there's no doubt that your beloved's response will be a resounding 'Yes!' <br/><br/>
                Make your love story the stuff of legends. Contact Royal Viking Yachts today and let us help you plan a proposal that will be etched in your hearts forever. Say "I do" to love, luxury, and a lifetime of memories.
            </p>

            <Link exact to="/Register"><button class="BookNow-btn-PYSH">BOOK NOW</button></Link>
     </div>
     <div class="left-div-PYSH">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="images/package-3/romantic1.webp" class="d-block  w-100 mt-4" alt="..."/>
              </div>
             
              <div class="carousel-item">
                <img src="images/package-3/romantic2.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/package-3/romantic3.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/package-3/romantic4.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
              <div class="carousel-item">
                <img src="images/package-3/romantic5.webp" class="d-block w-100 mt-4" alt="..."/>
              </div>
             
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
     </div>
   
    </section>
    





    <Footer/>
    </div>
    </>
  )
}
